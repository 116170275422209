import React, { FC, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { useRouter } from 'next/router';
import { motion } from 'framer-motion';
import { ifProp } from 'styled-tools';
import { useSession } from 'next-auth/react';
import { useWindowScroll } from 'react-use';
import { IMenuItem, INavigation } from '$models';
import { Container, Flex, FlexContent, Gutter } from '$components/layouts';
import { MegaMenu } from './mega-menu';
import { DesktopSearch } from './desktop-search';
import { DesktopMenu } from './desktop-menu';
import { useSite } from '~/store/site';
import { RouteKeys, useAuthentication, useCMSRoute } from '~/store';
import { useTranslation } from '$hooks';
import { Text } from '$components/elements/text';
import { Logo } from '$components/elements/logo';
import { SvgIcon } from '$components/elements/svg-icon';
import { MiniBasket } from '$components/elements/mini-basket';
import { Button } from '$components/elements/button';
import { Link } from '$components/elements/link';

export type DesktopNavigationProps = {
    menu: INavigation;
    className?: string;
};

const variants = { visible: { transform: 'translateY(0%)' }, hidden: { transform: 'translateY(-100%)' } };

export const DesktopNavigation: FC<DesktopNavigationProps> = ({ menu, className }: DesktopNavigationProps) => {
    const router = useRouter();
    const [subMenu, setSubMenu] = useState<IMenuItem | undefined>(undefined);
    const [subMenuActive, setSubMenuActive] = useState<boolean>(false);
    const [megaMenubackdropHeight, setMegaMenubackdropHeight] = useState<number>(0);
    const megaMenuRef = useRef<HTMLDivElement>(null);
    const { setSite } = useSite();
    const { y } = useWindowScroll();
    const theme = useTheme();
    const { routes } = useCMSRoute();
    const { data: session } = useSession();
    const { translate } = useTranslation();
    const { setShowMemberAccessPanel } = useAuthentication();

    useEffect(() => {
        const handleRouteChange = () => {
            setSubMenu(undefined);
        };
        router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [router]);

    useEffect(() => {
        const subMenuActive = Boolean(subMenu?.children?.length);
        setSite({ backdrop: subMenuActive });
        setSubMenuActive(subMenuActive);

        requestAnimationFrame(() => {
            if (megaMenuRef.current) {
                setMegaMenubackdropHeight(megaMenuRef.current.offsetHeight);
            }
        });

        return () => {
            setSite({ backdrop: false });
            setSubMenuActive(false);
        };
    }, [subMenu]);

    useEffect(() => {
        // Make sure header is stuck when dropdown is active
        setSite({ headerHideable: !subMenuActive });
    }, [subMenuActive]);

    return (
        <Navigation className={className} shadow={!subMenuActive && y > 0}>
            <Header>
                <Container>
                    <MenuWrapper onMouseEnter={() => setSubMenu(undefined)}>
                        <FlexContent>
                            <Logo size="large" />
                        </FlexContent>

                        {routes[RouteKeys.Search] && (
                            <FlexContent grow={1}>
                                <Gutter gutterSize="large">
                                    <Flex justifyContent="center">
                                        <DesktopSearch searchResultUrl={routes[RouteKeys.Search]} />
                                    </Flex>
                                </Gutter>
                            </FlexContent>
                        )}

                        <FlexContent>
                            <Flex alignItems="center" gap="small" justifyContent={'flex-end'}>
                                {!!menu.findStore?.path && (
                                    <QuickLink href={menu.findStore.path}>
                                        <Flex gap="extrasmall" alignItems="center">
                                            <SvgIcon size={theme.sizes.menuIcons} svg="pin1" />
                                            <Text fontWeight="Medium" size={theme.fontSizes.sm}>
                                                {menu.findStore.title}
                                            </Text>
                                        </Flex>
                                    </QuickLink>
                                )}

                                {session && !!menu.myPageHome?.path ? (
                                    <QuickLink href={menu.myPageHome.path}>
                                        <Flex gap="extrasmall" alignItems="center">
                                            <SvgIcon size={theme.sizes.menuIcons} svg="userAlt" />
                                            <Text fontWeight="Medium" size={theme.fontSizes.sm}>
                                                {menu.myPageHome.title}
                                            </Text>
                                        </Flex>
                                    </QuickLink>
                                ) : (
                                    <LoginLink variant="link" onClick={() => setShowMemberAccessPanel('login')}>
                                        <Flex gap="extrasmall" alignItems="center">
                                            <SvgIcon size={theme.sizes.menuIcons} svg="userAlt" />
                                            <Text fontWeight="Medium" size={theme.fontSizes.sm}>
                                                {translate('loginPage.login')}
                                            </Text>
                                        </Flex>
                                    </LoginLink>
                                )}

                                <MiniBasket />
                            </Flex>
                        </FlexContent>
                    </MenuWrapper>
                </Container>
                {!!menu.mainMenuItems?.length && (
                    <DesktopMenu setSubMenu={setSubMenu} activeSubMenu={subMenu} menu={menu} />
                )}
            </Header>
            <MegaMenuWrapper onMouseLeave={() => setSubMenu(undefined)}>
                <MegaMenuBackDrop
                    height={megaMenubackdropHeight}
                    variants={variants}
                    animate={subMenuActive ? 'visible' : 'hidden'}
                    transition={{ duration: 0.2, ease: [0.37, 0, 0.2, 1] }}
                    initial="hidden"
                />
                <StyledContainer>
                    {!!subMenu?.children?.length && <MegaMenu ref={megaMenuRef} menu={subMenu} />}
                </StyledContainer>
            </MegaMenuWrapper>
        </Navigation>
    );
};

const Navigation = styled(motion.nav)<{ shadow: boolean }>(
    ({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        minHeight: theme.sizes.mobileMenuHeight,
        justifyContent: 'center',
        background: theme.colors.white,
    }),
    ifProp('shadow', {
        boxShadow: '0px 0px 24px 0px rgba(0,0,0,0.1)',
    })
);

const Header = styled.div({});

const QuickLink = styled(Link)(({ theme }) => ({
    fontSize: theme.fontSizes.sm,
    fontWeight: theme.fontWeights.regular,
    fontFamily: theme.fontFamilies.regular,
    whiteSpace: 'nowrap',
}));

const MenuWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.space[9]} 0 ${theme.space[6]}`,
}));

const MegaMenuWrapper = styled.div({
    margin: '0 -40px',
    marginTop: '-10px',
    padding: '0 40px',
    paddingTop: '10px',
});

const MegaMenuBackDrop = styled(motion.div)<{ height: number }>(({ height, theme }) => ({
    position: 'absolute',
    backgroundColor: theme.colors.white,
    height: `${height}px`,
    top: '100%',
    left: 0,
    right: 0,
    zIndex: -1,
}));

const StyledContainer = styled(Container)({
    position: 'relative',
});

const LoginLink = styled(Button)({
    textDecoration: 'none',
});
