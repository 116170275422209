import React, { FC, forwardRef, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { Container, Grid } from '$components/layouts';
import { IMenuItem } from '$models';
import { Text } from '$components/elements/text';
import { Link } from '$components/elements/link';

export const MegaMenu = forwardRef<HTMLDivElement, { menu: IMenuItem; asSeo?: boolean }>(
    (props: { menu: IMenuItem; asSeo?: boolean }, ref) => {
        const { menu, asSeo } = props;

        return (
            <MegaMenuContainer ref={ref} seo={asSeo}>
                <Grid flow="row" columnGap="medium" columns="3fr 1fr">
                    <Categories>
                        {!!menu.categoryListHeading && (
                            <CategoryHeader as="h6" font="regular" fontWeight="Bold">
                                {menu.categoryListHeading}
                            </CategoryHeader>
                        )}
                        {!!menu.children && <SubMenuCategories menu={menu.children} />}
                    </Categories>
                    {!!menu.linkList?.length && (
                        <HighlightedLinks>
                            {!!menu.linkListHeading && (
                                <CategoryHeader as="h6" font="regular" fontWeight="Bold">
                                    {menu.linkListHeading}
                                </CategoryHeader>
                            )}
                            {!!menu.linkList && (
                                <Grid flow="row" rowGap="extrasmall">
                                    {menu.linkList?.map(
                                        (x, i) =>
                                            !!x.destinationUrl && (
                                                <StyledLink
                                                    key={`${x.destinationUrl}-${i}`}
                                                    href={x.destinationUrl}
                                                    target={x.destinationTarget}
                                                >
                                                    {x.linkText}
                                                </StyledLink>
                                            )
                                    )}
                                </Grid>
                            )}
                        </HighlightedLinks>
                    )}
                </Grid>
            </MegaMenuContainer>
        );
    }
);

const verticalPadding = 24;

const enter = keyframes({
    from: { opacity: 0 },
    to: { opacity: 1 },
});

const Categories = styled.div(({ theme }) => ({
    borderRight: `1px solid ${theme.colors.grey5}`,
}));

const MegaMenuContainer = styled.div<{ seo?: boolean }>(({ seo, theme }) => ({
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    paddingTop: theme.space[8],
    paddingBottom: theme.space[10],
    opacity: 0,
    animation: `${enter} 250ms ease-out 300ms forwards`,
    ...(seo && {
        display: 'none',
    }),
}));

const HighlightedLinks = styled(Container)(({ theme }) => ({
    padding: `${verticalPadding}px ${theme.space[6]}`,
    margin: `-${verticalPadding}px 0`,
    width: `calc(100% - ${theme.space[6]} - ${theme.space[6]})`,
    borderRadius: theme.general.borderRadius,
}));

const SubMenuCategories: FC<{ menu: IMenuItem[] }> = ({ menu }: { menu: IMenuItem[] }) => {
    const noOfColumns = 3;
    const [rowCount, setRowCount] = useState<number>(0);

    useEffect(() => {
        const maxCategoriesPerColumns = Math.ceil(menu.length / noOfColumns);

        setRowCount(maxCategoriesPerColumns || 0);
    }, [menu]);

    return (
        <Grid
            flow="column"
            columns={`repeat(${noOfColumns}, 1fr)`}
            rowGap="extrasmall"
            rows={`repeat(${rowCount}, 1fr)`}
        >
            {rowCount > 0 &&
                menu.map(
                    (x) =>
                        !!x.url && (
                            <StyledLink key={x.url} href={x.url} target={x.urlTarget}>
                                {x.text}
                            </StyledLink>
                        )
                )}
        </Grid>
    );
};

const CategoryHeader = styled(Text)(({ theme }) => ({
    fontSize: '15px',
    lineHeight: '19px',
    marginBottom: theme.space[3],
}));

const StyledLink = styled(Link)(({ theme }) => ({
    fontSize: theme.fontSizes.sm,
    fontWeight: theme.fontWeights.medium,
}));
