import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Container } from '$components/layouts';
import { IMenuItem, INavigation } from '$models';
import { DesktopMenuItem } from './desktop-menu-item';
import { key } from '~/utils';

type Props = {
    menu: INavigation;
    activeSubMenu?: IMenuItem;
    setSubMenu: (menu: IMenuItem | undefined) => void;
};

export const DesktopMenu: FC<Props> = ({ activeSubMenu, menu, setSubMenu }) => {
    const leftAlignedMenu = menu.mainMenuItems?.filter((i) => !i.showInRightSideMenu);
    const rightAlignedMenu = menu.mainMenuItems?.filter((i) => i.showInRightSideMenu);

    return (
        <MenuContainer>
            <MenuItemsList>
                {leftAlignedMenu?.map((x, i) => (
                    <DesktopMenuItem
                        isActive={!!(activeSubMenu && key(activeSubMenu) === key(x))}
                        highlight={x.url === menu.giftShop?.path}
                        setSubMenu={setSubMenu}
                        key={`${x.text}-${i}`}
                        menuItem={x}
                    />
                ))}
            </MenuItemsList>
            <MenuItemsList>
                {rightAlignedMenu?.map((x, i) => (
                    <DesktopMenuItem
                        isActive={!!(activeSubMenu && key(activeSubMenu) === key(x))}
                        highlight={x.url === menu.giftShop?.path}
                        setSubMenu={setSubMenu}
                        key={`${x.text}-${i}`}
                        menuItem={x}
                    />
                ))}
            </MenuItemsList>
        </MenuContainer>
    );
};

const MenuContainer = styled(Container)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    gap: 30,
}));
const MenuItemsList = styled.ul(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: theme.space[6],
    gap: 30,
}));
