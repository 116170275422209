import { useState } from 'react';
import { useRouter } from 'next/router';
import { useSearchHistory } from '~/modules/search/hooks/use-search-history.hook';

export interface SearchFocusContentItem {
    name?: string;
    url?: string;
}

export interface SearchFocusReturnProps {
    clearFocus?: () => void;
    focusIndex?: number;
    handleKeyPress?: (e: React.KeyboardEvent) => void;
    inFocus?: boolean;
    sectionIndex?: number;
    isSearchPage?: boolean;
    setIndexes?: (focus: number, section: number) => void;
    onSubmit?: () => void;
}

enum Key {
    ESCAPE = 'Escape',
    ENTER = 'Enter',
    ARROW_DOWN = 'ArrowDown',
    ARROW_UP = 'ArrowUp',
    ARROW_LEFT = 'ArrowLeft',
    ARROW_RIGHT = 'ArrowRight',
}

export function useSearchFocus(
    contentSections: SearchFocusContentItem[][],
    setOverlayState?: (state: boolean) => void,
    defaultSearchUrl?: string,
    searchTerm?: string,
    isSearchPage?: boolean
): SearchFocusReturnProps {
    const [inFocus, setInFocus] = useState<boolean>(false);
    const [focusIndex, setFocusIndex] = useState<number>(0);
    const [sectionIndex, setSectionIndex] = useState<number>(0);
    const { onAddHistoryItem } = useSearchHistory();
    const { push, replace } = useRouter();

    // if (!contentSections.length) {
    //     return {};
    // }

    const handleKeyPress = (e: React.KeyboardEvent) => {
        const { key } = e;

        if (key === Key.ENTER) {
            onEnter();
            return;
        }

        if (key === Key.ESCAPE) {
            clearFocus();
            setOverlayState?.(false);
            return;
        }

        if (!contentSections.length) {
            return;
        }
        if (key === Key.ARROW_UP || key === Key.ARROW_DOWN) {
            e.preventDefault?.(); // to prevent the cursor jumping back and forth in the input, while navigating.
        }

        if (!inFocus && (key === Key.ARROW_DOWN || key === Key.ARROW_UP)) {
            setInFocus(true);
            setOverlayState?.(true);

            if (key === Key.ARROW_UP) setPrevSectionIndex();
            return;
        }

        if (inFocus) {
            switch (key) {
                case Key.ARROW_UP:
                    shiftUp();
                    break;
                case Key.ARROW_DOWN:
                    shiftDown();
                    break;
                default:
                    clearFocus();
            }
        }
    };

    function clearFocus() {
        setInFocus(false);
        setFocusIndex(0);
        setSectionIndex(0);
    }

    function shiftUp() {
        if (focusIndex === 0) {
            setPrevSectionIndex();
        } else {
            setFocusIndex(focusIndex - 1);
        }
    }

    function shiftDown() {
        if (focusIndex === contentSections[sectionIndex]?.length - 1) {
            setNextSectionIndex();
        } else {
            setFocusIndex(focusIndex + 1);
        }
    }

    function setPrevSectionIndex(startAt?: number) {
        let prevIndex = startAt != null ? ++startAt : sectionIndex || contentSections.length;
        for (let i = contentSections.length; i >= 0; i--) {
            prevIndex--;
            if (prevIndex === 0 && !contentSections[prevIndex]?.length) {
                prevIndex = contentSections.length;
            }
            if (contentSections[prevIndex]?.length !== 0) {
                setSectionIndex(prevIndex);
                setFocusIndex(contentSections[prevIndex]?.length - 1);
                break;
            }
        }
    }

    function setNextSectionIndex(startAt?: number) {
        let nextIndex = startAt != null ? --startAt : sectionIndex;
        for (let i = contentSections.length; i >= 0; i--) {
            nextIndex++;
            if (nextIndex === contentSections.length) {
                setSectionIndex(0);
                break;
            }
            if (contentSections[nextIndex]?.length) {
                setSectionIndex(nextIndex);
                break;
            }
        }
        setFocusIndex(0);
    }

    function onEnter() {
        if (!searchTerm) {
            return;
        }
        if (searchTerm) {
            onAddHistoryItem(searchTerm);
        }
        const goto = defaultSearchUrl;
        // const goto = inFocus ? contentSections[sectionIndex]?.[focusIndex]?.url : defaultSearchUrl;
        handleRoutePush(goto);
    }

    function handleRoutePush(route?: string) {
        if (route) {
            if (isSearchPage) {
                replace(route);
            } else {
                push(route);
            }
            clearFocus();
            setOverlayState?.(false);
        }
    }

    function setIndexes(focus: number, section: number) {
        if (!inFocus) {
            setInFocus(true);
        }
        setFocusIndex(focus);
        setSectionIndex(section);
    }

    function onSubmit() {
        if (!searchTerm) {
            return;
        }
        if (searchTerm) {
            onAddHistoryItem(searchTerm);
        }
        handleRoutePush(defaultSearchUrl);
    }

    return {
        clearFocus,
        focusIndex,
        handleKeyPress,
        inFocus,
        sectionIndex,
        setIndexes,
        onSubmit,
    };
}
