import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ifNotProp, ifProp } from 'styled-tools';
import { IMenuItem } from '$models';
import { Text } from '$components/elements/text';
import { Link } from '$components/elements/link';
import { MegaMenu } from './mega-menu';

type Props = {
    menuItem: IMenuItem;
    isActive: boolean;
    highlight: boolean;
    setSubMenu: (menu: IMenuItem | undefined) => void;
};

export const DesktopMenuItem: FC<Props> = ({ menuItem, isActive, highlight, setSubMenu }: Props) => {
    return (
        <>
            {menuItem.url && (
                <li>
                    <MenuItemLink
                        onMouseEnter={() => {
                            setSubMenu(menuItem.children?.length ? menuItem : undefined);
                        }}
                        isActive={isActive}
                        highlight={highlight}
                        href={menuItem.url}
                        target={menuItem.urlTarget}
                    >
                        <StyledText variant="body" fontWeight="Medium" highlight={highlight}>
                            {menuItem.text}
                        </StyledText>
                    </MenuItemLink>

                    {!!menuItem.children?.length && <MegaMenu menu={menuItem} asSeo />}
                </li>
            )}
        </>
    );
};

const MenuItemLink = styled(Link)<{ isActive: boolean; highlight: boolean }>(
    ({ theme, isActive, highlight }) => ({
        whiteSpace: 'nowrap',
        padding: `${theme.space[1]} 0 `,
        borderBottom: `3px solid transparent`,
        color: isActive
            ? highlight
                ? theme.colors.gift
                : theme.colors.red
            : highlight
            ? theme.colors.gift
            : 'inherit',
    }),

    ifProp('isActive', ({ theme, highlight }) => ({
        borderBottomColor: highlight ? theme.colors.gift : theme.colors.red,
    })),
    ifNotProp('isActive', ({ theme, highlight }) => ({
        ':hover': {
            color: highlight ? theme.colors.gift : theme.colors.red,
            borderBottomColor: highlight ? theme.colors.gift : theme.colors.red,
        },
    }))
);

const StyledText = styled(Text)<{ highlight: boolean }>(({ theme }) => ({
    fontSize: `clamp(${theme.fontSizes.xxxxs}, 1.2vw, ${theme.fontSizes.md})`,
    borderBottom: `3px solid transparent`,
    paddingBottom: theme.space[2],
}));
